import constants from "../Utils/constants";

const initialState = {
  isBlogsLoading: false,
  isBlogPreviewLoading:false,
  blogData: [],
  blogPreviewData: []
};

export const blogReducer = (state = initialState, action) => {
  switch (action.type) {
    case constants.GET_BLOGS_REQUEST:
      return {
        ...state,
        isBlogsLoading: true,
      };
    case constants.GET_BLOGS_SUCCESS:
      return {
        ...state,
        isBlogsLoading: false,
        blogData: action.payload,
      };
    case constants.GET_BLOGS_FAILURE:
      return {
        ...state,
        isBlogsLoading: false,
      };
    case constants.GET_BLOG_PREVIEW_REQUEST:
      return {
        ...state,
        isBlogPreviewLoading: true,
      };
    case constants.GET_BLOG_PREVIEW_SUCCESS:
      return {
        ...state,
        isBlogPreviewLoading: false,
        blogPreviewData: action.payload,
      };
    case constants.GET_BLOG_PREVIEW_FAILURE:
      return {
        ...state,
        isBlogPreviewLoading: false,
      };
    default:
      return state;
  }
};
