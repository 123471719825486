import React, { useEffect, useLayoutEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Grid from "@mui/material/Grid2";
import {
  Alert,
  Box,
  CircularProgress,
  Dialog,
  Divider,
  Paper,
  Popover,
  Snackbar,
  Typography,
  Zoom,
} from "@mui/material";
import { useBreakpoints } from "../../Utils/ResponsiveBreakpoints";
import styles from "../Blogs/Blogs.module.css";
import blogsTextStyles from "./BlogsText.module.css";
import { useDimensions } from "../../CommonMethods";
import "swiper/css";
import "swiper/css/pagination";
import { useTheme } from "../../ThemeContext";
import { getBlogPreview, getBlogs } from "../../Actions/blogActions";
import {
  ArrowIcon,
  CalendarIcon,
  CategoryIcon,
  PenIcon,
  ReadTimeIcon,
  ShareIcon,
} from "../../Utils/SvgImage";
import { Link, useNavigate, useParams } from "react-router-dom";
import { BlocksRenderer } from "@strapi/blocks-react-renderer";
import copyLink from "../../assets/copyLink.svg";
import facebook from "../../assets/facebook.svg";
import twitter from "../../assets/twitter.svg";
import linkedIn from "../../assets/linkedIn.svg";
import CommentEmbed from "../CommentEmbed";
import _ from "lodash";

const BlogsText = () => {
  const { IsXS, IsSM, IsMD, IsXL, IsXXL } = useBreakpoints();
  const { themeMode, toggleTheme } = useTheme();
  const { width } = useDimensions();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { slug } = useParams();
  const [anchorEl, setAnchorEl] = useState(null);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [openImage, setOpenImage] = useState(false);
  const [visibleRange, setVisibleRange] = useState({ start: 0, end: 5 });
  const [filterBlogData, setFilterBlogData] = useState([]);

  const loadMoreBlogs = () => {
    setVisibleRange((prevRange) => ({
      ...prevRange,
      end: prevRange.end + 5, // Show 5 more blogs when clicked
    }));
  };

  const open = Boolean(anchorEl);
  const id = open ? "share-popover" : undefined;

  const handleOpen = () => {
    setOpenImage(true);
  };
  const handleCloseImage = () => {
    setOpenImage(false);
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleCloseSnackbar = () => {
    setSnackbarOpen(false);
  };

  const { blogData, isBlogPreviewLoading, blogPreviewData } = useSelector(
    (state) => state.blogs
  );

  useLayoutEffect(() => {
    window.scrollTo({ top: 0 });
    dispatch(getBlogPreview(slug, successCallback));
    dispatch(getBlogs());
  }, [dispatch, slug]);

  const successCallback = (res) => {
    const validSlug =
      res?.data[0]?.attributes?.Slug === slug && res?.data.length > 0;
    if (!validSlug) {
      navigate("/blogs");
    }
  };
  useEffect(() => {
    if (blogData?.data && blogData.data.length > 0) {
      const filteredData = _.filter(
        blogData.data,
        (o) => o.attributes?.Slug !== slug
      );
      setFilterBlogData(filteredData);
    }
  }, [blogData, slug]);

  if (
    !blogPreviewData?.data ||
    blogPreviewData.data.length === 0 ||
    isBlogPreviewLoading
  ) {
    return (
      <div
        style={{
          height: "400px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          width: "100%",
        }}
      >
        <CircularProgress />
      </div>
    );
  }

  const blogUrl = `${window.location.origin}/blogs/${slug}`;

  const shareCard = [
    {
      icon: copyLink,
      text: "Copy link",
      action: () => {
        navigator.clipboard.writeText(blogUrl);
        setSnackbarOpen(true);
      },
    },
    {
      icon: linkedIn,
      text: "Share on LinkedIn",
      url: `https://www.linkedin.com/shareArticle?mini=true&url=${blogUrl}&title=Check+out+this+article!`,
    },
    {
      icon: facebook,
      text: "Share on Facebook",
      url: `https://www.facebook.com/sharer/sharer.php?u=${blogUrl}`,
    },
    {
      icon: twitter,
      text: "Share on X",
      url: `https://twitter.com/intent/tweet?text=Check+out+this+article!&url=${blogUrl}`,
    },
  ];
  const blogTitle =
    blogPreviewData?.data?.length > 0 &&
    blogPreviewData?.data[0]?.attributes?.Hero?.Title;

  const disqusShortname = "defx";
  const disqusConfig = {
    url: blogUrl,
    identifier: slug,
    title: blogTitle,
  };

  return (
    <Grid container justifyContent="center">
      <Grid size={{ xs: 11, sm: 11, md: 9, lg: 6, xl: 5, xxl: 4 }} mt={10}>
        <Grid container justifyContent="center" rowSpacing={2}>
          <Grid size={12}>
            <Grid container>
              {blogPreviewData?.data?.length > 0 &&
                blogPreviewData?.data.map((value, index) => {
                  const imageUrl =
                    value?.attributes?.Hero?.Media[0]?.Image?.data?.attributes
                      ?.url;
                  const publishDate = value?.attributes?.Publish_Date;
                  const date = publishDate
                    ? new Date(publishDate).toISOString().split("T")[0]
                    : null;
                  const title = value?.attributes?.Hero?.Title;
                  const timeToRead = value?.attributes?.Time_to_read;
                  const author = value?.attributes?.Author;
                  const description = value?.attributes?.Hero?.Description;
                  const url = value?.attributes?.Hero?.custom_Url[0]?.Url;
                  const addColumn = value?.attributes?.Hero?.Add_Column;
                  const categories = value?.attributes?.categories?.data;
                  {
                    return (
                      <Grid key={index} size={12}>
                        <Grid container rowSpacing={6}>
                          <Grid size={12}>
                            <Grid container rowSpacing={2} alignItems="center">
                              <Grid size={12}>
                                <Grid container justifyContent="end" mb={1}>
                                  <Grid
                                    onClick={handleClick}
                                    sx={{ cursor: "pointer" }}
                                  >
                                    <ShareIcon
                                      color={
                                        themeMode === "light"
                                          ? "#191919"
                                          : "#fbfbfb"
                                      }
                                    />
                                  </Grid>
                                </Grid>
                                <Grid
                                  container
                                  className={styles.imageContainer}
                                  sx={{
                                    backgroundImage: `url(${process.env.REACT_APP_API_URL}${imageUrl}) !important`,
                                    height: IsXS ? "300px" : "600px",
                                  }}
                                ></Grid>
                              </Grid>

                              <Grid size={12}>
                                <Grid
                                  container
                                  justifyContent="center"
                                  columnSpacing={3}
                                  rowSpacing={1}
                                >
                                  <Grid>
                                    {author && (
                                      <Typography
                                        className={styles.author}
                                        style={{
                                          display: "flex",
                                          alignItems: "center",
                                          gap: "8px",
                                        }}
                                      >
                                        <PenIcon
                                          color={
                                            themeMode === "light"
                                              ? "#191919"
                                              : "#fbfbfb"
                                          }
                                        />
                                        {author}
                                      </Typography>
                                    )}
                                  </Grid>

                                  <Grid>
                                    <Typography
                                      className={styles.author}
                                      style={{
                                        display: "flex",
                                        alignItems: "center",
                                        gap: "8px",
                                      }}
                                    >
                                      <ReadTimeIcon
                                        color={
                                          themeMode === "light"
                                            ? "#191919"
                                            : "#fbfbfb"
                                        }
                                      />
                                      {timeToRead} mins to read
                                    </Typography>
                                  </Grid>

                                  <Grid>
                                    <Typography
                                      className={styles.author}
                                      style={{
                                        display: "flex",
                                        alignItems: "center",
                                        gap: "8px",
                                      }}
                                    >
                                      <CalendarIcon
                                        color={
                                          themeMode === "light"
                                            ? "#191919"
                                            : "#fbfbfb"
                                        }
                                      />
                                      {date}
                                    </Typography>
                                  </Grid>

                                  <Grid>
                                    <Grid container gap="8px">
                                      {categories?.map((category, index) => (
                                        <Grid key={index}>
                                          <Typography
                                            className={styles.author}
                                            style={{
                                              display: "flex",
                                              alignItems: "center",
                                              gap: "8px",
                                            }}
                                          >
                                            {index === 0 ? (
                                              <CategoryIcon
                                                color={
                                                  themeMode === "light"
                                                    ? "#191919"
                                                    : "#fbfbfb"
                                                }
                                              />
                                            ) : (
                                              <span>•</span>
                                            )}
                                            {category?.attributes?.Title}
                                          </Typography>
                                        </Grid>
                                      ))}
                                    </Grid>
                                  </Grid>
                                </Grid>
                              </Grid>
                            </Grid>
                          </Grid>
                          <Grid size={12}>
                            <Grid container rowSpacing={2}>
                              <Grid size={12}>
                                <Typography
                                  className={blogsTextStyles.blogTitle}
                                >
                                  {title}
                                </Typography>
                              </Grid>
                              <Grid size={12}>
                                <Grid
                                  container
                                  rowSpacing={4}
                                  columnSpacing={2}
                                >
                                  {addColumn?.length > 0 &&
                                    addColumn?.map((item, index) => {
                                      const columnImageUrl =
                                        item?.Media?.data?.attributes?.url;
                                      return (
                                        <Grid
                                          size={
                                            item?.Size === "Full_Screen"
                                              ? 12
                                              : 6
                                          }
                                          key={index}
                                        >
                                          <Grid
                                            container
                                            rowSpacing={1}
                                            columnGap={2}
                                          >
                                            {item?.Media?.data === null ? (
                                              " "
                                            ) : item?.Size === "Full_Screen" ? (
                                              <>
                                                <Grid
                                                  size={12}
                                                  className={styles.imageCenter}
                                                >
                                                  <img
                                                    src={`${process.env.REACT_APP_API_URL}${columnImageUrl}`}
                                                    alt="blogs-image"
                                                    width={
                                                      IsXL || IsXXL
                                                        ? "80%"
                                                        : "100%"
                                                    }
                                                    style={{
                                                      objectFit: "contain",
                                                      borderRadius: "3px",
                                                      maxHeight: "500px",
                                                      cursor: "zoom-in",
                                                    }}
                                                    onClick={handleOpen}
                                                  />
                                                  <Box
                                                    sx={{
                                                      display: "flex",
                                                      justifyContent: "center",
                                                    }}
                                                  >
                                                    <Dialog
                                                      open={openImage}
                                                      onClose={handleCloseImage}
                                                      maxWidth="lg"
                                                      PaperProps={{
                                                        style: {
                                                          backgroundColor:
                                                            "transparent",
                                                          boxShadow: "none",
                                                          margin: 0,
                                                        },
                                                      }}
                                                    >
                                                      <img
                                                        src={`${process.env.REACT_APP_API_URL}${columnImageUrl}`}
                                                        alt="blogs-image"
                                                        style={{
                                                          objectFit: "contain",
                                                          borderRadius: "3px",
                                                          maxHeight: "800px",
                                                          cursor: "zoom-out",
                                                        }}
                                                        onClick={
                                                          handleCloseImage
                                                        }
                                                      />
                                                    </Dialog>
                                                  </Box>
                                                </Grid>
                                                <Grid size={12}>
                                                  <Typography
                                                    className={
                                                      styles.imageCredit
                                                    }
                                                  >
                                                    {item.Title}
                                                  </Typography>
                                                </Grid>
                                              </>
                                            ) : (
                                              <Grid size={12} height="100%">
                                                <Grid container height="100%">
                                                  <Grid size={12}>
                                                    <img
                                                      src={`${process.env.REACT_APP_API_URL}${columnImageUrl}`}
                                                      alt="blogs-image"
                                                      width="100%"
                                                      height="100%"
                                                      style={{
                                                        objectFit: "cover",
                                                        borderRadius: "3px",
                                                      }}
                                                    />
                                                  </Grid>
                                                  <Grid size={12} mt="6px">
                                                    <Typography
                                                      className={
                                                        styles.imageCredit
                                                      }
                                                    >
                                                      {item.Title}
                                                    </Typography>
                                                  </Grid>
                                                </Grid>
                                              </Grid>
                                            )}
                                            <Grid size={12}>
                                              <BlocksRenderer
                                                content={item?.Description}
                                                blocks={{
                                                  paragraph: ({ children }) => (
                                                    <Grid container>
                                                      <Grid size={12}>
                                                        <Typography
                                                          className={
                                                            styles.blogText
                                                          }
                                                        >
                                                          {children}
                                                        </Typography>
                                                      </Grid>
                                                    </Grid>
                                                  ),
                                                  heading: ({
                                                    children,
                                                    level,
                                                  }) => (
                                                    <Grid container>
                                                      <Grid size={12} mb={1}>
                                                        <Typography
                                                          variant={`h${
                                                            level || 1
                                                          }`}
                                                          className={
                                                            styles.blogHeading
                                                          }
                                                          style={{
                                                            fontSize:
                                                              [
                                                                "36px",
                                                                "32px",
                                                                "28px",
                                                                "24px",
                                                                "20px",
                                                                "18px",
                                                              ][level - 1] ||
                                                              "36px",
                                                          }}
                                                        >
                                                          {children}
                                                        </Typography>
                                                      </Grid>
                                                    </Grid>
                                                  ),
                                                  link: ({ children, url }) => (
                                                    <Link
                                                      to={url}
                                                      style={{
                                                        color:
                                                          themeMode === "light"
                                                            ? "#191919"
                                                            : "#fbfbfb",
                                                      }}
                                                      className={
                                                        styles.blogText
                                                      }
                                                    >
                                                      {children}
                                                    </Link>
                                                  ),
                                                  list: ({
                                                    children,
                                                    format,
                                                  }) => {
                                                    const listStyle = {
                                                      ...(format ===
                                                        "unordered" && {
                                                        paddingLeft: "20px",
                                                        listStyleType: "circle",
                                                      }),
                                                      ...(format ===
                                                        "ordered" && {
                                                        paddingLeft: "30px",
                                                        listStyleType:
                                                          "decimal",
                                                      }),
                                                    };

                                                    return (
                                                      <ul
                                                        style={listStyle}
                                                        className={
                                                          styles.blogList
                                                        }
                                                      >
                                                        {children}
                                                      </ul>
                                                    );
                                                  },
                                                  listItem: ({ children }) => (
                                                    <li
                                                      className={
                                                        styles.blogListItem
                                                      }
                                                    >
                                                      {children}
                                                    </li>
                                                  ),
                                                  image: ({ image }) => (
                                                    <Grid
                                                      container
                                                      justifyContent="center"
                                                      my={2}
                                                    >
                                                      <Grid>
                                                        <img
                                                          src={image?.url}
                                                          alt={
                                                            image?.alternativeText ||
                                                            "Image"
                                                          }
                                                          style={{
                                                            width: "100%",
                                                            maxHeight: "400px",
                                                            borderRadius: "3px",
                                                            objectFit:
                                                              "contain",
                                                          }}
                                                          className={
                                                            styles.blogImage
                                                          }
                                                        />
                                                      </Grid>
                                                    </Grid>
                                                  ),
                                                  code: ({ children }) => (
                                                    <Grid
                                                      container
                                                      justifyContent="center"
                                                      my={2}
                                                    >
                                                      <Grid size={12}>
                                                        <pre
                                                          style={{
                                                            backgroundColor:
                                                              "#f5f5f5",
                                                            padding: "15px",
                                                            borderRadius: "5px",
                                                            overflowX: "auto",
                                                          }}
                                                        >
                                                          <code
                                                            style={{
                                                              color: "#d6336c",
                                                              fontFamily:
                                                                "'Courier New', Courier, monospace",
                                                            }}
                                                          >
                                                            {children}
                                                          </code>
                                                        </pre>
                                                      </Grid>
                                                    </Grid>
                                                  ),
                                                  quote: ({ children }) => (
                                                    <Grid container>
                                                      <Grid size={12}>
                                                        <blockquote>
                                                          <Typography
                                                            className={
                                                              styles.blogText
                                                            }
                                                          >
                                                            "{children}"
                                                          </Typography>
                                                        </blockquote>
                                                      </Grid>
                                                    </Grid>
                                                  ),
                                                }}
                                                modifiers={{
                                                  bold: ({ children }) => (
                                                    <span
                                                      className={
                                                        styles.blogDescriptionBold
                                                      }
                                                    >
                                                      {children}
                                                    </span>
                                                  ),
                                                  code: ({ children }) => (
                                                    <code
                                                      className={
                                                        styles.blogDescriptionCode
                                                      }
                                                    >
                                                      {children}
                                                    </code>
                                                  ),
                                                  italic: ({ children }) => (
                                                    <span
                                                      className={
                                                        styles.blogItalic
                                                      }
                                                    >
                                                      {children}
                                                    </span>
                                                  ),
                                                }}
                                              />
                                            </Grid>

                                            {/* {item?.Media?.data === null ? (
                                            " "
                                          ) : item?.Size === "Full_Screen" ? (
                                            <Grid size={12}>
                                              <img
                                                src={`${process.env.REACT_APP_API_URL}${columnImageUrl}`}
                                                alt="blogs-image"
                                                width="100%"
                                                height={
                                                  IsXS ? "300px" : "600px"
                                                }
                                                style={{
                                                  // objectFit: "cover",
                                                  borderRadius: "3px",
                                                  // marginBottom:'5px'
                                                }}
                                                //   onClick={() => navigate(`${url}`)}
                                              />
                                            </Grid>
                                          ) : (
                                            <Grid size={6}>
                                              <img
                                                src={`${process.env.REACT_APP_API_URL}${columnImageUrl}`}
                                                alt="blogs-image"
                                                width="100%"
                                                height="300px"
                                                style={{
                                                  // objectFit: "cover",
                                                  borderRadius: "3px",
                                                  // marginBottom:'5px'
                                                }}
                                                //   onClick={() => navigate(`${url}`)}
                                              />
                                            </Grid>
                                          )} */}
                                          </Grid>
                                        </Grid>
                                      );
                                    })}
                                </Grid>
                              </Grid>
                            </Grid>
                          </Grid>
                          <Popover
                            id={id}
                            open={open}
                            anchorEl={anchorEl}
                            onClose={handleClose}
                            anchorOrigin={{
                              vertical: "bottom",
                              horizontal: "center",
                            }}
                            transformOrigin={{
                              vertical: "top",
                              horizontal: "center",
                            }}
                            sx={{
                              "& .MuiPaper-root": {
                                borderRadius: "10px",
                              },
                            }}
                          >
                            <Grid
                              container
                              direction="column"
                              rowSpacing={1}
                              sx={{ p: 2, background: "#fbfbfb" }}
                            >
                              {shareCard.map((item, index) => (
                                <Grid
                                  key={index}
                                  className={styles.shareData}
                                  onClick={() => {
                                    if (item.action) {
                                      item.action();
                                    } else if (item.url) {
                                      window.open(
                                        item.url,
                                        "_blank",
                                        "noopener,noreferrer"
                                      );
                                    }
                                    handleClose();
                                  }}
                                >
                                  <img
                                    src={item.icon}
                                    alt={item.text}
                                    style={{
                                      // width: 24,
                                      // height: 24,
                                      marginRight: 12,
                                    }}
                                  />
                                  <Typography className={styles.shareText}>
                                    {item.text}
                                  </Typography>
                                </Grid>
                              ))}
                            </Grid>
                          </Popover>
                          <Snackbar
                            open={snackbarOpen}
                            autoHideDuration={3000} // Automatically close after 3 seconds
                            onClose={handleCloseSnackbar}
                            anchorOrigin={{
                              vertical: "top",
                              horizontal: "center",
                            }} // Positioning
                          >
                            <Alert
                              onClose={handleCloseSnackbar}
                              severity="success"
                              sx={{
                                backgroundColor:
                                  themeMode === "light" ? "#191919" : "#fbfbfb",
                                color:
                                  themeMode === "light" ? "#fbfbfb" : "#191919",
                                fontWeight: "bold",
                                boxShadow: "0px 2px 10px rgba(0, 0, 0, 0.2)",
                              }}
                            >
                              Link copied
                            </Alert>
                          </Snackbar>
                        </Grid>
                      </Grid>
                    );
                  }
                })}
            </Grid>
          </Grid>
          <Grid size={12} mt={10}>
            <Grid container justifyContent="center">
              <Grid size={12}>
                <CommentEmbed
                  shortname={disqusShortname}
                  config={disqusConfig}
                />
              </Grid>
            </Grid>
          </Grid>
          {
            <Grid size={12} my={10}>
              <Divider sx={{ borderColor: "#ccc" }} />
            </Grid>
          }
          <Grid size={12}>
            <Grid container justifyContent="flex-start">
              <Grid>
                <Typography className={styles.blogMainTitle} fontSize="30px">
                  Other Blogs
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid size={12}>
            <Grid container rowSpacing={IsXS ? 5 : ""}>
              {filterBlogData.length > 0
                ? filterBlogData
                    ?.slice(visibleRange.start, visibleRange.end)
                    .map((value, index) => {
                      const imageUrl =
                        value?.attributes?.Hero?.Media[0]?.Image?.data
                          ?.attributes?.url;
                      const publishDate = value?.attributes?.Publish_Date;
                      const date = publishDate
                        ? new Date(publishDate).toISOString().split("T")[0]
                        : null;

                      const title = value?.attributes?.Hero?.Title;
                      const author = value?.attributes?.Author;
                      const url = value?.attributes?.Slug;
                      const timeToRead = value?.attributes?.Time_to_read;
                      const description = value?.attributes?.Hero?.Description;
                      // const actualIndex = visibleRange.start + index;
                      const categories = value?.attributes?.categories?.data;
                      const isLastIndex =
                        blogData?.data?.slice(
                          visibleRange.start,
                          visibleRange.end
                        ).length -
                          1 ===
                        index;
                      const allText = description
                        .flatMap(
                          (block) =>
                            block.children?.map((child) => child.text) || []
                        )
                        .join(" ");
                      const truncatedText =
                        allText.split(" ").slice(0, 25).join(" ") + " ...";
                      const truncatedContent = [
                        {
                          type: "paragraph",
                          children: [{ text: truncatedText, type: "text" }],
                        },
                      ];

                      {
                        return (
                          <Grid key={index} size={12}>
                            <Grid container rowSpacing={1} columnSpacing={2}>
                              <Grid size={{ xs: 12, sm: 12, md: 4 }}>
                                <img
                                  src={`${process.env.REACT_APP_API_URL}${imageUrl}`}
                                  alt="blogs-image"
                                  height={IsSM ? "350px" : "250px"}
                                  className={styles.image}
                                  onClick={() => {
                                    navigate(`/blogs/${url}`);
                                  }}
                                />
                              </Grid>
                              <Grid size={{ xs: 12, sm: 12, md: 8 }}>
                                <Grid container rowSpacing={2}>
                                  <Grid size={12}>
                                    <Grid container>
                                      {IsXS || IsSM ? (
                                        <Grid size={12}>
                                          <Typography
                                            className={styles.categoryItem}
                                          >
                                            {timeToRead} mins to read
                                          </Typography>
                                        </Grid>
                                      ) : (
                                        <Grid size={12}>
                                          {categories?.length > 0 &&
                                            categories?.map((item, index) => {
                                              return (
                                                <span
                                                  className={
                                                    styles.categoryItem
                                                  }
                                                  key={index}
                                                >
                                                  {item?.attributes?.Title}
                                                  {index <
                                                    categories.length - 1 && (
                                                    <span>
                                                      {" "}
                                                      &nbsp;&nbsp;•&nbsp;&nbsp;{" "}
                                                    </span>
                                                  )}
                                                </span>
                                              );
                                            })}
                                        </Grid>
                                      )}
                                      <Grid size={12}>
                                        <Typography
                                          className={styles.blogTitle}
                                          onClick={() => {
                                            navigate(`/blogs/${url}`);
                                          }}
                                        >
                                          {title}
                                        </Typography>
                                      </Grid>
                                    </Grid>
                                  </Grid>
                                  {IsXS || IsSM ? (
                                    <Grid size={12}>
                                      <Grid container spacing={3}>
                                        <Grid>
                                          {author && (
                                            <Typography
                                              className={styles.author}
                                              style={{
                                                display: "flex",
                                                alignItems: "center",
                                                gap: "8px",
                                              }}
                                            >
                                              <PenIcon
                                                color={
                                                  themeMode === "light"
                                                    ? "#191919"
                                                    : "#fbfbfb"
                                                }
                                              />
                                              {author}
                                            </Typography>
                                          )}
                                        </Grid>

                                        <Grid>
                                          <Typography
                                            className={styles.author}
                                            style={{
                                              display: "flex",
                                              alignItems: "center",
                                              gap: "8px",
                                            }}
                                          >
                                            <CalendarIcon
                                              color={
                                                themeMode === "light"
                                                  ? "#191919"
                                                  : "#fbfbfb"
                                              }
                                            />
                                            {date}
                                          </Typography>
                                        </Grid>
                                      </Grid>
                                    </Grid>
                                  ) : (
                                    ""
                                  )}
                                  <Grid
                                    onClick={() => {
                                      navigate(`/blogs/${url}`);
                                    }}
                                  >
                                    <BlocksRenderer
                                      content={truncatedContent}
                                      blocks={{
                                        paragraph: ({ children }) => (
                                          <Typography
                                            className={styles.blogDescription}
                                          >
                                            {children}
                                          </Typography>
                                        ),
                                        heading: ({ children, level }) => (
                                          <Grid container>
                                            <Grid size={12}>
                                              <Typography
                                                variant={`h${level || 1}`}
                                                className={styles.blogHeading}
                                                style={{
                                                  fontSize:
                                                    [
                                                      "32px",
                                                      "28px",
                                                      "24px",
                                                      "20px",
                                                      "18px",
                                                      "16px",
                                                    ][level - 1] || "32px",
                                                }}
                                              >
                                                {children}
                                              </Typography>
                                            </Grid>
                                          </Grid>
                                        ),
                                        link: ({ children, url }) => (
                                          <Link
                                            to={url}
                                            style={{ color: "white" }}
                                            className={styles.blogDescription}
                                          >
                                            {children}
                                          </Link>
                                        ),
                                      }}
                                      modifiers={{
                                        bold: ({ children }) => (
                                          <span
                                            className={
                                              styles.blogDescriptionBold
                                            }
                                          >
                                            {children}
                                          </span>
                                        ),
                                        italic: ({ children }) => (
                                          <span
                                            className={styles.blogDescription}
                                          >
                                            {children}
                                          </span>
                                        ),
                                      }}
                                    />
                                  </Grid>
                                  {IsXS ? (
                                    <Grid size={12}>
                                      <Typography
                                        className={styles.blogDescription}
                                        sx={{
                                          display: "flex",
                                          alignItems: "center",
                                          justifyContent: "center",
                                        }}
                                        onClick={() => {
                                          navigate(`/blogs/${url}`);
                                        }}
                                      >
                                        Read more{" "}
                                        <span
                                          style={{
                                            marginLeft: "5px",
                                            display: "flex",
                                            alignItems: "center",
                                          }}
                                        >
                                          <ArrowIcon
                                            color={
                                              themeMode === "light"
                                                ? "#191919"
                                                : "#fbfbfb"
                                            }
                                          />
                                        </span>
                                      </Typography>
                                    </Grid>
                                  ) : (
                                    ""
                                  )}

                                  {IsXS || IsSM ? (
                                    ""
                                  ) : (
                                    <Grid size={12}>
                                      <Grid container spacing={3}>
                                        <Grid>
                                          {author && (
                                            <Typography
                                              className={styles.author}
                                              style={{
                                                display: "flex",
                                                alignItems: "center",
                                                gap: "8px",
                                              }}
                                            >
                                              <PenIcon
                                                color={
                                                  themeMode === "light"
                                                    ? "#191919"
                                                    : "#fbfbfb"
                                                }
                                              />
                                              {author}
                                            </Typography>
                                          )}
                                        </Grid>

                                        <Grid>
                                          <Typography
                                            className={styles.author}
                                            style={{
                                              display: "flex",
                                              alignItems: "center",
                                              gap: "8px",
                                            }}
                                          >
                                            <ReadTimeIcon
                                              color={
                                                themeMode === "light"
                                                  ? "#191919"
                                                  : "#fbfbfb"
                                              }
                                            />
                                            {timeToRead} mins to read
                                          </Typography>
                                        </Grid>
                                        <Grid>
                                          <Typography
                                            className={styles.author}
                                            style={{
                                              display: "flex",
                                              alignItems: "center",
                                              gap: "8px",
                                            }}
                                          >
                                            <CalendarIcon
                                              color={
                                                themeMode === "light"
                                                  ? "#191919"
                                                  : "#fbfbfb"
                                              }
                                            />
                                            {date}
                                          </Typography>
                                        </Grid>
                                      </Grid>
                                    </Grid>
                                  )}
                                </Grid>
                              </Grid>
                              {!IsXS && !isLastIndex && (
                                <Grid size={12} my={4}>
                                  <Divider sx={{ borderColor: "#ccc" }} />
                                </Grid>
                              )}
                            </Grid>
                          </Grid>
                        );
                      }
                    })
                : "no data"}
            </Grid>
          </Grid>
          <Grid
            size={12}
            sx={{ display: "flex", justifyContent: "center" }}
            my={8}
          >
            {visibleRange.end < blogData.data?.length && (
              <Typography className={styles.loadMore} onClick={loadMoreBlogs}>
                See More
              </Typography>
            )}
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default BlogsText;
