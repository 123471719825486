import React, { useEffect, useState } from "react";
import { getHeaders } from "../../Actions/headerAction";
import { useDispatch, useSelector } from "react-redux";
import { useTheme } from "../../ThemeContext";
import Grid from "@mui/material/Grid2";
import Logo from "../../assets/Logo.svg";
import styles from "./Header.module.css";
import { Divider, Drawer, IconButton, Typography } from "@mui/material";
import DarkModeOutlinedIcon from "@mui/icons-material/DarkModeOutlined";
import WbSunnyOutlinedIcon from "@mui/icons-material/WbSunnyOutlined";
import { useBreakpoints } from "../../Utils/ResponsiveBreakpoints";
import { MenuIcon } from "../../Utils/SvgImage";
import { useLocation, useNavigate } from "react-router-dom";

const Header = ({ onScrollToSection }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const { themeMode, toggleTheme } = useTheme();
  const dispatch = useDispatch();
  const { IsXS, IsSM } = useBreakpoints();
  const { headerData, isHeaderLoading } = useSelector((state) => state.header);
  const [drawerOpen, setDrawerOpen] = useState(false);

  const toggleDrawer = () => {
    setDrawerOpen(!drawerOpen);
  };

  useEffect(() => {
    dispatch(getHeaders());
  }, [dispatch]);

  // const handleNavigation = (link) => {
  //   if (link === "/home") {
  //     navigate("/");
  //   } else if(link === "/whoWeAre"){
  //     navigate("/whoweare")
  //   } else {
  //     const sectionId = link.replace("/", "");
  //     onScrollToSection(sectionId);
  //   }
  // };

  const handleNavigation = (path) => {
    const isHomePage = location.pathname === "/";

    if (path === "/whoWeAre") {
      navigate("/whoweare");
    } else if (path === "/blogs") {
      navigate(path);
    } else if (isHomePage) {
      const sectionId = path.replace("/", "");
      document
        .getElementById(sectionId)
        ?.scrollIntoView({ behavior: "smooth" });
    } else {
      navigate("/");
      setTimeout(() => {
        const sectionId = path.replace("/", "");
        document
          .getElementById(sectionId)
          ?.scrollIntoView({ behavior: "smooth" });
      }, 500);
    }
  };

  return (
    <Grid container justifyContent="center">
      <Grid
        size={{ xs: 11, sm: 11, md: 11, lg: 8, xl: 7, xxl: 5 }}
        paddingY={2}
      >
        <Grid container justifyContent="space-between" alignItems="center">
          <Grid>
            <img
              src={Logo}
              onClick={() => navigate("/")}
              style={{ cursor: "pointer" }}
            />
          </Grid>
          <Grid>
            {!(IsXS || IsSM) && (
              <Grid container className={styles.navContainer}>
                {headerData?.data?.length > 0 &&
                  headerData?.data?.sort((a, b) => {
                    const positionA = a?.attributes?.Position ?? 0;
                    const positionB = b?.attributes?.Position ?? 0;
                    return positionA - positionB;
                  })
                    ?.filter((headers) => {
                      const internalLinks =
                        headers?.attributes?.Mapping_Links[0]?.Internal_Links[0]
                          ?.Internal_Links;
                      return [
                        "/home",
                        "/services",
                        "/products",
                        "/contact_us",
                        "/whoWeAre",
                        "/blogs",
                      ].includes(internalLinks);
                    })
                    ?.map((headers, index) => {
                      const navItem = headers?.attributes?.Title;
                      const internalLinks =
                        headers?.attributes?.Mapping_Links[0]?.Internal_Links[0]
                          ?.Internal_Links;
                      return (
                        <Grid item key={index}>
                          <Typography
                            className={styles.navItem}
                            onClick={() => handleNavigation(internalLinks)}
                          >
                            {navItem}
                          </Typography>
                        </Grid>
                      );
                    })}
              </Grid>
            )}
          </Grid>
          <Grid
            className={styles.themeIcon}
            sx={{ "-webkit-tap-highlight-color": "transparent" }}
          >
            {themeMode === "light" ? (
              <DarkModeOutlinedIcon onClick={toggleTheme} />
            ) : (
              <WbSunnyOutlinedIcon onClick={toggleTheme} />
            )}

            {(IsXS || IsSM) && (
              <div onClick={toggleDrawer} style={{ marginLeft: "30px" }}>
                <MenuIcon
                  color={themeMode === "light" ? "#191919" : "#fbfbfb"}
                />
              </div>
            )}
          </Grid>
        </Grid>
      </Grid>
      <Grid size={12}>
        <Divider sx={{ borderColor: "#ccc" }} />
      </Grid>
      <Drawer anchor="top" open={drawerOpen} onClose={toggleDrawer}>
        <Grid
          container
          direction="column"
          className={styles.navItemsContainer}
          rowSpacing={3}
          paddingY={4}
          paddingX={2}
          sx={{ background: themeMode === "light" ? "#fbfbfb" : "#191919" }}
        >
          <Grid>
            <Grid container justifyContent="space-between">
              <Grid>
                <img
                  src={Logo}
                  onClick={() => navigate("/")}
                  style={{ cursor: "pointer" }}
                />
              </Grid>
              <Grid>
                <div onClick={toggleDrawer} style={{ cursor: "pointer" }}>
                  <MenuIcon
                    color={themeMode === "light" ? "#191919" : "#fbfbfb"}
                  />
                </div>
              </Grid>
            </Grid>
          </Grid>
          {headerData?.data
            ?.sort((a, b) => {
              const positionA = a?.attributes?.Position ?? 0;
              const positionB = b?.attributes?.Position ?? 0;
              return positionA - positionB;
            })
            ?.filter((headers) => {
              const internalLinks =
                headers?.attributes?.Mapping_Links[0]?.Internal_Links[0]
                  ?.Internal_Links;
              return [
                "/home",
                "/services",
                "/products",
                "/whoWeAre",
                "/blogs",
                "/contact_us",
              ].includes(internalLinks);
            })
            ?.map((headers, index) => {
              const navItem = headers?.attributes?.Title;
              const internalLinks =
                headers?.attributes?.Mapping_Links[0]?.Internal_Links[0]
                  ?.Internal_Links;
              return (
                <Grid item key={index}>
                  <Typography
                    className={styles.navItem}
                    onClick={() => {
                      handleNavigation(internalLinks);
                      toggleDrawer();
                    }}
                    color={themeMode === "light" ? "#191919" : "#fbfbfb"}
                  >
                    {navItem}
                  </Typography>
                </Grid>
              );
            })}
        </Grid>
      </Drawer>
    </Grid>
  );
};

export default Header;
